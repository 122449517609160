Px.CMS.LiveReloadForms = {

  onLiveFormSubmit: function(evt, options) {
    const form = evt.target;

    if (form.getAttribute('data-px-live-reload') === 'true') {
      evt.preventDefault();

      if (form.reportValidity()) {
        options = Object.assign({}, options || {});
        if (form.getAttribute('data-px-live-reload-selectors')) {
          try {
            options.selectors = JSON.parse(form.getAttribute('data-px-live-reload-selectors'));
          } catch (e) {
            console.error(e);
          }
        }

        if (options.selectors) {
          options.selectors.forEach(selector => {
            const element = document.querySelector(selector);
            if (element) {
              element.classList.add('px-live-fragment-loading');
            }
          });
        } else {
          form.classList.add('px-live-fragment-loading');
        }

        fetch(form.action, {
          method: form.method,
          body: new FormData(form)
        }).then(response => {
          return response.text();
        }).then(html => {
          Px.CMS.morph(html, options);
        });
      } else {
        // FF doesn't always want to display the error bubble,
        // try to force it by invoking reportValidity after a timeout.
        setTimeout(() => form.reportValidity(), 1);
      }
    }
  },

  onAutoSubmitControlChange: function(evt) {
    const control = evt.target;
    const form = control.closest('form');
    if (form && control.reportValidity &&
                (control.getAttribute('data-px-auto-submit') === 'true' ||
                 form.getAttribute('data-px-auto-submit') === 'true')) {
      if (control.reportValidity()) {
        if (form.requestSubmit) {
          // This is the modern API that's not well supported in Safari yet.
          form.requestSubmit();
        } else {
          // form.dispatchEvent does not actually trigger form submission in Webkit browsers,
          // so we have to add a hidden button and click it to trigger submission.
          // We can't use form.submit() because that doesn't trigger the submit event.
          const button = document.createElement('button');
          button.style.display = 'none';
          form.appendChild(button);
          button.click();
          setTimeout(() => button.remove());
        }
      } else {
        // FF doesn't always want to display the error bubble,
        // try to force it by invoking reportValidity after a timeout.
        setTimeout(() => control.reportValidity(), 1);
      }
    }
  }

};

document.addEventListener('submit', Px.CMS.LiveReloadForms.onLiveFormSubmit);
document.addEventListener('change', Px.CMS.LiveReloadForms.onAutoSubmitControlChange);
