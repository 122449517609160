const px$self = self;

import '../common/js/framework.js';
import '../javascripts/jquery-rails.js';
import './init.js';
import './main.js';
import './helpers.js';
import './live_reload_forms.js';
import './theme_filters.js';
import './mapped_previews.js';
import './variant_forms.js';
import './components/carousel.js';
import './components/image_adjust_tool_component.js';
import './components/photo_prints_component.js';
import './components/upload_dialog_component.js';
import './web_components/preview_base.js';
import './web_components/px_date_selector.js';
import './web_components/px_design_preview.js';
import './web_components/px_multi_image_upload.js';
import './web_components/px_image_adjust_tool.js';
import './web_components/px_image_upload.js';
import './web_components/px_page_preview.js';
import './web_components/px_product_price.js';
import './web_components/px_project_preview.js';
import './web_components/px_option_selector.js';
import './web_components/px_upload_dialog.js';

// Redefine self that's being imported into global namespace from mobx
self = px$self;

// CSS
import './common.css';
import './components/css/carousel.css';
import './components/css/image_adjust_tool_component.css';
import './components/css/photo_prints_component.css';
import './components/css/upload_dialog_component.css';
import './web_components/css/px_image_upload.css';
import './web_components/css/px_multi_image_upload.css';
import './web_components/css/px_upload_dialog.css';
