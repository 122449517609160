Px.CMS.PreviewBase = class PreviewBase extends HTMLElement {

  static get observedAttributes() {
    return [
      'alt',
      'crop-bleed',
      'format',
      'height',
      'hide-placeholders',
      'hide-resolution-warnings',
      'hide-scorelines',
      'loading',
      'mapped-preview-settings',
      'option-selector',
      'size',
      'timestamp',
      'title',
      'width'
    ];
  }

  constructor() {
    super();
    this.style_node = null;
    this.container_node = null;
    this.has_intersected = false;
    this._update_timeout = null;
    this._input_handler_timeout = null;
    this.attachShadow({mode: 'open'});
    this.globalChangeHandler = this.globalChangeHandler.bind(this);
    this.globalInputHandler = this.globalInputHandler.bind(this);
    this.intersectionObserver = new IntersectionObserver(this.intersectionHandler.bind(this), {rootMargin: '200px'});
  }

  connectedCallback() {
    document.addEventListener('change', this.globalChangeHandler);
    document.addEventListener('input', this.globalInputHandler);

    this.style_node = this.createStyle();
    this.shadowRoot.append(this.style_node);

    this.container_node = document.createElement('img');
    this.container_node.setAttribute('alt', '');
    this.shadowRoot.append(this.container_node);

    this.intersectionObserver.observe(this.container_node);

    this.scheduleUpdate();
  }

  disconnectedCallback() {
    clearTimeout(this._update_timeout);
    clearTimeout(this._input_handler_timeout);

    document.removeEventListener('change', this.globalChangeHandler);
    document.removeEventListener('input', this.globalInputHandler);

    this.intersectionObserver.disconnect();

    this.style_node.remove();
    this.style_node = null;
    this.container_node.remove();
    this.container_node = null;

    if (this.mapped_preview_component) {
      this.mapped_preview_component.destroy();
      this.mapped_preview_component = null;
    }
  }

  attributeChangedCallback() {
    if (this.isConnected) {
      this.scheduleUpdate();
    }
  }

  scheduleUpdate() {
    clearTimeout(this._update_timeout);
    this._update_timeout = setTimeout(() => this.updatePreview(), 0);
  }

  globalChangeHandler(evt) {
    for (const observed_selector of this.observedOptionSelectors()) {
      if (observed_selector.contains(evt.target)) {
        this.scheduleUpdate();
        return;
      }
    }
  }

  // Throttled version of the change handler.
  globalInputHandler(evt) {
    clearTimeout(this._input_handler_timeout);
    this._input_handler_timeout = setTimeout(() => {
      this.globalChangeHandler(evt);
    }, 500);
  }

  intersectionHandler(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (!this.has_intersected) {
          this.has_intersected = true;
          this.scheduleUpdate();
        }
      }
    });
  }

  observedOptionSelectors() {
    const ids = (this.getAttribute('option-selector') || '').split(' ').map(id => id.trim()).filter(id => id);
    const option_selectors = [];

    for (const id of ids) {
      if (id) {
        const option_selector = document.getElementById(id);
        if (option_selector) {
          option_selectors.push(option_selector);
        }
      }
    }

    return option_selectors;
  }

  format() {
    const format = this.getAttribute('format');
    if (format === 'jpg' || format === 'png' || format === 'webp') {
      return format;
    } else {
      return 'svg';
    }
  }

  updateContainerNode() {
    this.container_node.setAttribute('part', 'img');
    this.container_node.setAttribute('title', this.getAttribute('title') || '');
    this.container_node.setAttribute('alt', this.getAttribute('alt') || this.getAttribute('title') || '');
  }

  updatePreview() {
    if (this.getAttribute('loading') === 'lazy' && !this.has_intersected) {
      return;
    }

    this.updateContainerNode();

    const mapped_preview_settings = this.mappedPreviewSettings();

    if (mapped_preview_settings) {
      const current_settings = this.container_node.getAttribute('data-current-mapped-preview-settings');
      if (current_settings === JSON.stringify(mapped_preview_settings)) {
        return;
      }
      this.container_node.setAttribute('data-current-mapped-preview-settings', JSON.stringify(mapped_preview_settings));
      this.container_node.removeAttribute('data-current-url');
      this.container_node.setAttribute('data-loading', 'true');
      const mapped_preview = Px.Components.MappedPreview.make(Object.assign(mapped_preview_settings, {
        onRender: () => {
          if (mapped_preview !== this.mapped_preview_component) {
            // Ignore callbacks from components that have been replaced.
            return;
          }
          const is_loaded = this.mapped_preview_component.loaded;
          this.mapped_preview_component.toBlob(blob => {
            if (blob) {
              this.renderMappedPreview(blob);
              if (is_loaded) {
                this.container_node.removeAttribute('data-loading');
                this.runOnLoadHandler();
              } else {
                this.runOnUpdateHandler();
              }
            }
          });
        }
      }));
      this.mapped_preview_component = mapped_preview;
      this.mapped_preview_component.mount(document.createElement('div'));
    } else {
      const preview_url = this.previewUrl();
      const current_url = this.container_node.getAttribute('data-current-url');
      if (current_url === preview_url) {
        return;
      }
      this.container_node.setAttribute('data-current-url', preview_url);
      this.container_node.removeAttribute('data-current-mapped-preview-settings');
      if (this.mapped_preview_component) {
        this.mapped_preview_component.destroy();
        this.mapped_preview_component = null;
      }
      this.renderRegularPreview(preview_url);
    }
  }

  renderRegularPreview(preview_url) {
    if (this._image_loader) {
      this._image_loader.dispose();
    }

    this.container_node.setAttribute('data-loading', 'true');

    if (this.format() === 'svg') {
      this._image_loader = new PreviewBase.SVGImageLoader(preview_url);
      this._image_loader.onupdate = (svg) => {
        // The SVGImageLoader loads all external images and embeds them as object URLs
        // since external links don't work when using <img> elements with SVG src attributes.
        // The loader invokes this callback every time it loads an external image with the updated SVG text.
        const old_src = this.container_node.src;
        this.container_node.src = URL.createObjectURL(new Blob([svg], {type: 'image/svg+xml'}));
        if (old_src) {
          URL.revokeObjectURL(old_src);
        }
        this.runOnUpdateHandler();
      };
      this._image_loader.onload = () => {
        this.container_node.removeAttribute('data-loading');
        this.runOnLoadHandler();
      };
    } else {
      this._image_loader = new PreviewBase.RasterImageLoader(preview_url);
      this._image_loader.onload = () => {
        this.container_node.src = preview_url;
        this.container_node.removeAttribute('data-loading');
        this.runOnLoadHandler();
      };
    }

    this._image_loader.load();
  }

  renderMappedPreview(blob) {
    const old_src = this.container_node.src;
    this.container_node.src = URL.createObjectURL(blob);
    if (old_src) {
      URL.revokeObjectURL(old_src);
    }
  }

  previewUrlBase(format) {
    throw new Error('Implement in subclass');
  }

  sizeParams() {
    const params = {};
    if (this.getAttribute('width')) {
      params.width = parseInt(this.getAttribute('width'), 10);
    }
    if (this.getAttribute('height')) {
      params.height = parseInt(this.getAttribute('height'), 10);
    }
    if (this.getAttribute('size')) {
      params.size = parseInt(this.getAttribute('size'), 10);
    }
    return params;
  }

  previewUrlParams() {
    const params = {};

    if (this.getAttribute('hide-placeholders') === 'false') {
      params.hide_placeholders = 'false';
    }
    if (this.getAttribute('hide-resolution-warnings') === 'false') {
      params.hide_resolution_warnings = 'false';
    }
    if (this.getAttribute('hide-scorelines') === 'false') {
      params.hide_scorelines = 'false';
    }
    if (this.getAttribute('crop-bleed') === 'false') {
      params.crop = 'false';
    }
    if (this.getAttribute('timestamp')) {
      params.ts = this.getAttribute('timestamp');
    }

    for (const option_selector of this.observedOptionSelectors()) {
      Object.assign(params, option_selector.values({skipInvalid: true, skipNoElementSubstitutions: true}));
    }

    const size_params = this.sizeParams();

    if (size_params.width || size_params.size) {
      params.width = size_params.width || size_params.size;
    }
    if (size_params.height || size_params.size) {
      params.height = size_params.height || size_params.size;
    }

    return params;
  }

  previewUrl(params, format) {
    params = params || this.previewUrlParams();
    format = format || this.format();
    let url = this.previewUrlBase(format);
    if (url) {
      const query = new URLSearchParams(params);
      url += `?${query}`;
    }
    return url;
  }

  previewSectionUrl(name) {
    const params = this.previewUrlParams();
    params.preview_section = name;
    return this.previewUrl(params, 'webp');
  }

  mappedPreviewSettings() {
    const settings_json = this.getAttribute('mapped-preview-settings');
    if (!settings_json) {
      return null;
    }

    const settings = JSON.parse(settings_json);
    const data = Object.assign({
      bg_url: settings.bg_url || '',
      glb_url: settings.glb_url || null,
      preview_sections: {}
    }, this.sizeParams());

    if (settings.preview_sections) {
      settings.preview_sections.forEach(section => {
        data.preview_sections[section.name] = this.previewSectionUrl(section.name);
      });
    }

    return data;
  }

  runOnLoadHandler() {
    if (this.onload) {
      try {
        this.onload();
      } catch (err) {
        console.error('onload handler failed', err);
      }
    }
  }

  runOnUpdateHandler() {
    if (this.onupdate) {
      try {
        this.onupdate();
      } catch (err) {
        console.error('onupdate handler failed', err);
      }
    }
  }

  createStyle() {
    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.innerHTML = `
      :host {
        display: contents;
      }
      img {
        max-height: 100%;
        max-width: 100%;
        transition: opacity 0.1s 0.25s;
      }
      img[data-loading="true"] {
        opacity: 0.5;
      }
    `;
    return style;
  }

};

Px.CMS.PreviewBase.RasterImageLoader = class RasterImageLoader {
  constructor(src) {
    this.src = src;
  }

  dispose() {
    this.disposed = true;
  }

  load() {
    const img = new Image();
    img.onload = () => {
      if (this.onload && !this.disposed) {
        this.onload.call(null);
      }
    };
    img.src = this.src;
  }
};

Px.CMS.PreviewBase.SVGImageLoader = class SVGImageLoader {
  constructor(src) {
    this.src = src;
    this._textarea = document.createElement('textarea');
  }

  dispose() {
    this.disposed = true;
  }

  unescapeHTML(html) {
    this._textarea.innerHTML = html;
    return this._textarea.value;
  }

  load() {
    fetch(this.src, {mode: 'cors'}).then(response => response.text()).then(svg_text => {
      if (this.disposed) {
        return;
      }

      // NOTE: We only search xlink:href attributes wrapped in double quotes,
      //       because I believe our renderer only ever outputs SVGs with double quotes.
      const image_urls = {};
      for (const match of svg_text.matchAll(/xlink:href="(.*?)"/g)) {
        if (!image_urls[match[0]]) {
          image_urls[match[0]] = {
            original: match[1],
            decoded: this.unescapeHTML(match[1])
          };
        }
      }

      if (this.onupdate) {
        this.onupdate.call(null, svg_text);
      }

      if (Object.keys(image_urls).length === 0) {
        if (this.onload) {
          this.onload.call(null);
        }
      }

      Object.keys(image_urls).forEach(image_key => {
        const urls = image_urls[image_key];

        fetch(urls.decoded, {mode: 'cors'}).then(response => response.blob()).then(blob => {
          const reader = new FileReader();
          reader.onload = () => {
            if (this.disposed) {
              return;
            }

            const replacement = image_key.replace(urls.original, reader.result);
            svg_text = svg_text.replaceAll(image_key, replacement);
            delete image_urls[image_key];

            if (this.onupdate) {
              this.onupdate.call(null, svg_text);
            }
            if (this.onload) {
              if (Object.keys(image_urls).length === 0) {
                this.onload.call(null);
              }
            }
          };
          reader.readAsDataURL(blob);
        });
      });
    });
  }
};
